/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html,
body,
#root {
  height: 100%;
}
.ant-typography ul.paddingLeft20,
.ant-typography ol.paddingLeft20 {
  padding-left: 20px;
}
.paddingTop20 {
  padding-top: 20px;
}
.colorWeak {
  -webkit-filter: invert(80%);
          filter: invert(80%);
}
.ant-layout {
  min-height: 100vh;
}
.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  left: unset;
}
.search .ant-card-body {
  padding-bottom: 0;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
  list-style: none;
}
.ant-pro-sider-logo img.show-xs {
  display: none;
}
@media (max-width: 480px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-table-thead > tr > th > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-tbody > tr > td > span {
    display: block;
  }
  .hidden-sm {
    display: none;
  }
  .ant-pro-sider-logo img.show-xs {
    display: none;
  }
}
@media (max-width: 768px) {
  .hidden-sm {
    display: none;
  }
  .ant-pro-sider-logo img.show-xs {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .hidden-sm {
    display: none;
  }
  .ant-pro-sider-logo img.show-xs {
    display: block;
  }
}
.SuccessButtonColor {
  color: #346e17d6;
  background-color: rgba(190, 252, 159, 0.692);
}
.SuccessButtonColor:hover {
  background-color: rgba(190, 252, 159, 0.24);
  border-color: #1c5e02;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}
.ant-table-tbody > tr.spot_cost {
  background-color: #f0f5ff;
}
.ant-table-tbody > tr.spot_cost > td:first-child {
  border-left: 3px solid #2f54eb;
}
.ant-table-tbody > tr.spot_rate {
  background-color: #f0f5ff;
}
.ant-table-tbody > tr.bin_locked {
  background-color: #e88574;
}
.ant-table-tbody > tr.spot_rate > td:first-child {
  border-left: 3px solid #2f54eb;
}
.ant-table-tbody > tr.scanning_row {
  background-color: #d6e4ff;
}
.ant-table-tbody > tr.scanning_row > td:first-child {
  border-left: 3px solid #2f54eb;
}
.ant-table-tbody > tr.scanning_row > td:last-child {
  border-right: 3px solid #2f54eb;
}
.ant-table-tbody > tr.scanned_row {
  background-color: #dbffdb;
}
.ant-table-tbody > tr.sr_direct_line {
  background-color: #d6e4ff;
}
.ant-table-tbody > tr.sort_completed {
  background-color: #dbffdb;
}
.ant-table-tbody > tr.sort_uncompleted {
  background-color: #ff848a;
}
.ant-table-tbody > tr.alert_product_type .ant-table-tbody > tr.alert_product_type td {
  background: #faad14;
}
.ant-table-tbody > tr.error_product_type,
.ant-table-tbody > tr.error_product_type td {
  background: #ff848a;
}
.wms_layout .ant-menu-item-group-title {
  color: #42526e;
  color: var(--ds-text-subtle, #42526e);
  font-weight: 700;
  font-size: 11px;
  line-height: 1.45455;
  text-transform: uppercase;
}
.ant-menu-item:hover {
  background-color: #e6effc;
  background-color: var(--ds-background-selected-resting, #e6effc);
}
.ant-menu-item-disabled:hover {
  background-color: #fff;
}
.ant-menu-item:hover .in_icon {
  display: block;
}
.in_icon,
.ant-menu.ant-menu-inline-collapsed .in_icon {
  position: absolute;
  top: 10px;
  right: 15px;
  display: none;
  font-size: 24px;
}
.no-display-help-explain .ant-form-item-with-help .ant-form-item-explain {
  height: 0;
  min-height: 0;
  opacity: 0;
}
.no-display-help-explain .ant-form-item {
  margin-bottom: 16px;
}
.ant-layout-header .ant-pro-global-header-layout-mix {
  background-color: #fff;
}
.ant-pro-global-header .ant-pro-global-header-logo a img {
  height: 38px;
}
.ant-layout-header .ant-pro-global-header-layout-mix .anticon {
  color: #000;
}
.ant-pro-global-header {
  box-shadow: 0 2px 4px rgba(0, 21, 41, 0.15);
}
.ant-pro-global-header .ant-pro-global-header-layout-mix .ant-pro-global-header-logo h1 {
  color: #000;
}
.ant-layout-sider {
  background-color: #fafbfc;
  background-color: var(--ds-surface, #fafbfc);
}
.small-form-space .ant-form-item {
  margin-bottom: 12px !important;
}
.small-form-space .ant-card-head-title {
  padding-top: 0px;
  padding-bottom: 0px;
}
.delivery-address .ant-pro-table-search {
  margin-bottom: 0px;
  padding: 12px 0;
}
.delivery-address .ant-pro-card-body {
  padding: 12px 0;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 0px;
}
.largeLabel .ant-form-item-label label {
  font-size: 18px;
}
.ant-page-header-footer {
  margin-top: 0;
}
.anim-box .ant-input:hover,
.anim-box .ant-input:focus,
.anim-box .ant-input-focused {
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.anim-box .ant-input {
  width: 100%;
  height: 40px;
  padding: 0;
  padding-bottom: 10px;
  font-size: 22px;
  background-color: #fff;
  border-color: #fff;
}
.anim-box .ant-input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1;
  /* Firefox */
}
.anim-box .ant-input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1;
  /* Firefox */
}
.anim-box .ant-input::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1;
  /* Firefox */
}
.anim-box .ant-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1;
  /* Firefox */
}
.anim-box .ant-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999;
}
.anim-box .ant-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999;
}
.anim-box .ant-input[disabled] {
  background-color: #f5f5f5;
  border: none;
}
/* barcode container */
.anim-box {
  position: relative;
  width: 320px;
  height: 50px;
  padding: 10px 15px;
  background-color: #fff;
  transition: transform 0.6s ease-out;
}
/* adds the 4 corners */
.anim-box:before,
.anim-box:after,
.anim-box > :first-child:before,
.anim-box > :first-child:after {
  position: absolute;
  width: 15px;
  height: 25%;
  border-color: #000;
  border-style: solid;
  content: ' ';
}
/* top left corner */
.anim-box:before {
  top: 0;
  left: 0;
  border-width: 2px 0 0 2px;
}
/* top right corner */
.anim-box:after {
  top: 0;
  right: 0;
  border-width: 2px 2px 0 0;
}
/* bottom right corner */
.anim-box > :first-child:before {
  right: 0;
  bottom: 0;
  border-width: 0 2px 2px 0;
}
/* bottom left corner */
.anim-box > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 2px 2px;
}
/* grow on hover */
.anim-box-foucus-input:before,
.anim-box-foucus-input:after,
.anim-box-foucus-input > :first-child:before,
.anim-box-foucus-input > :first-child:after {
  border-color: #597ef7;
}
/* top left corner */
.anim-box-foucus-input:before {
  top: 0;
  left: 0;
  border-width: 3px 0 0 3px;
}
/* top right corner */
.anim-box-foucus-input:after {
  top: 0;
  right: 0;
  border-width: 3px 3px 0 0;
}
/* bottom right corner */
.anim-box-foucus-input > :first-child:before {
  right: 0;
  bottom: 0;
  border-width: 0 3px 3px 0;
}
/* bottom left corner */
.anim-box-foucus-input > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 3px 3px;
}
.anim-box-scale {
  transform: scale(1.1);
}
/* animated laser beam */
.scanner {
  position: relative;
  top: 50%;
  z-index: 10000;
  width: 100%;
  height: 2px;
  background-color: #000;
  opacity: 0.7;
  animation-name: scan;
  animation-duration: 4s;
  animation-play-state: running;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes scan {
  0% {
    top: 50%;
  }
  25% {
    top: 5px;
  }
  75% {
    top: 98%;
  }
}
.logListProTable .ant-table {
  margin-right: -24px;
  margin-left: -24px;
}
.logListTable .ant-table {
  font-size: 20px;
}
.logListTable .ant-table-tbody > tr.ant-table-row:hover > td,
.logListTable .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #b0c1f9;
}
.logListTable .ant-table-tbody > tr.ant-table-row .sorting-product-action {
  display: none;
}
.logListTable .ant-table-tbody > tr.ant-table-row:hover .sorting-product-action {
  display: block;
}
.hidden-container .selected-container-icon {
  display: none;
}
.selected-container .selected-container-icon {
  display: block;
}
.selected-container .selected-container-card {
  background-color: #dbffdb;
}
.smallMargin .ant-form-item {
  margin-bottom: 12px;
}
.smallMargin .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.7);
  background-color: #c5c5c5;
  border-color: #aaaaaa;
}
.large-notify-error {
  width: 484px;
  color: #f56c6c;
  background-color: #fef0f0;
}
.large-notify-warning {
  width: 484px;
  color: #ffe58f;
  background-color: #fffbe6;
}
.large-notify-info {
  width: 484px;
  color: #b7eb8f;
  background-color: #f6ffed;
}
.middle-warning {
  width: 484px;
}
.marketplace-order .ant-form-item {
  margin-bottom: 12px;
}
.order-large-notify-success {
  width: 484px;
  color: #14b639;
  background-color: #d6e4ff;
}
.wms-form-item .ant-form-item {
  margin-bottom: 12px;
  font-size: 18px;
}
.wms-form-item .ant-form-item-label > label {
  font-size: 18px;
}
